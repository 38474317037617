import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
    dsn: 'https://6987acd3de7ed2c12f4932222c988de6@o4507274591731712.ingest.us.sentry.io/4507291488813056',
    tracesSampleRate: 1.0
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
